
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const base = namespace("base");
const supplier = namespace("supplier");
const marketing = namespace("marketing");
@Component({})
@Table("loadListData")
export default class ReturnAppraise extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryCardTemplateList;
  @marketing.Action queryRevisitCommentList;
  @marketing.Action queryStaffAccountList;
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  orderStatusList: any[] = [];
  cardTemplateTypeList: any = [];
  supplierList: any = [];
  settlementList: any = [];
  cardTemplateList: any[] = [];
  countTypeList: any[] = [];
  searchForm = {
    firstName: "",
    mobile: "",
    revisitType: "",
    revisitStatus: "",
    purchaseDateBegin: "",
    purchaseDateEnd: "",
    cardTemplateName: "",
    cardType: "",
    userCode: ""
  };
  //计算属性
  get searchList() {
    return [
      {
        label: t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "firstName"
      },
      {
        label: t("v210801.contact-number"),
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: t("v210831.return-visit-method"),
        type: "select",
        selectList: this.orderStatusList,
        value: "",
        prop: "revisitType"
      },
      {
        label: t("v210831.return-visit-status"),
        type: "select",
        selectList: this.settlementList,
        value: "",
        prop: "revisitStatus"
      },
      {
        label: t("v210831.return-visitor"),
        type: "select-search",
        selectList: this.countTypeList,
        value: "",
        prop: "visitorCode",
        searchFun: this.searchSupplier
      }
    ];
  }
  searchSupplier(val = "") {
    this.queryStaffAccountList({
      pageNum: 1,
      pageSize: 10,
      staffName: val
    }).then(data => {
      this.countTypeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.staffName;
            item.value = item.userCode;
            return item;
          })
        : [];
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryRevisitCommentList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      // console.log("[ data ]", data.data.list);
      this.cardTemplateList = data.data.list;
      return data;
    });
  }
  created() {
    this.init();
  }
  mounted() {}
  async init() {
    this.loadListData();
    this.dictionary();
  }
  dictionary() {
    this.getDictionaryList("revisit_type").then(data => {
      this.orderStatusList = data;
    });
    this.getDictionaryList("revisit_status").then(data => {
      this.settlementList = data;
    });
    this.queryStaffAccountList().then(data => {
      let newArr = [];
      this.$safeValue(data, "data.list", []).forEach(element => {
        newArr.push({
          label: element.staffName,
          value: element.userCode
        });
      });
      this.countTypeList = newArr;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
        // this.searchForm.userCode = item.value;
      }
    });
    this.loadListData();
  }
  goSetRule() {
    this.$router.push("/marketing/return-rule-set");
  }
  toReturn(row) {
    this.$router.push(`/marketing/return/${row.revisitCommentCode}`);
  }
  handleView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}`
    });
    window.open(href, "_blank");
  }
  handleReturn(row) {
    this.$router.push({
      name: "marketingReturn",
      params: {
        row
      }
    });
  }
  //   ///
}
